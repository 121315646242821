import React from 'react';
import { FirebaseProvider } from './context/FirebaseProvider';
import FullPageSpinner from './components/FullPageSpinner';
import firebase from 'firebase/app';
import message from 'antd/lib/message';
import './style/Home.scss';
import { AuthStatus, useAuth } from '@montel/montelpro-shared-components/providers';
import { UserMappingProvider } from './context/UserMappingProvider';

const Home = React.lazy(() => import('./pages/Home'));

const App: React.FC = () => {
	const { authStatus, user, NotLoggedIn, isLoggedIn } = useAuth();
	const isUserDeactivated = user && user.token && !user.token.active;

	React.useEffect(() => {
		const showBrowserFirebaseSupportMessage = () => {
			message.warning({
				content: 'Your Browser does not support the Montel notification feature.',
				duration: 3,
			});
		};

		!firebase.messaging.isSupported() && showBrowserFirebaseSupportMessage();
	}, []);

	if (authStatus === AuthStatus.SHOULD_LOGIN || isUserDeactivated) {
		return <NotLoggedIn />;
	}

	return (
		<React.Suspense fallback={<FullPageSpinner />}>
			{isLoggedIn && (
				<UserMappingProvider>
					{firebase.messaging.isSupported() ? (
						<FirebaseProvider>
							<Home />
						</FirebaseProvider>
					) : (
						<Home />
					)}
				</UserMappingProvider>
			)}
		</React.Suspense>
	);
};

export default App;
