import React from 'react';
import { useLocation } from 'react-router-dom';
import { AuthProvider } from '@montel/montelpro-shared-components/providers';
import { setDefaultAuthorizationHeader } from '@montel/montelpro-shared-components/utils';
import config from '../config';

const AppProvider: React.FC = ({ children }) => {
	const { loginRedirect, fusionauthApplicationId, authServer } = config;
	const location = useLocation();

	React.useEffect(() => {
		function storeQueryFrontSymbol() {
			const search = location.search;
			const params = new URLSearchParams(search);
			const frontsymbol = params.get('frontsymbol');

			if (frontsymbol) {
				localStorage.setItem('frontsymbol', frontsymbol);
			}
		}
		storeQueryFrontSymbol();
	}, [location.search]);

	return (
		<AuthProvider
			authServerUri={authServer ?? ''}
			applicationId={fusionauthApplicationId ?? ''}
			returnUrl={loginRedirect ?? ''}
			onNewAccessToken={async (token: string) => {
				setDefaultAuthorizationHeader(token);
			}}
		>
			{children}
		</AuthProvider>
	);
};

export default AppProvider;
