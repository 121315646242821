import React, { useState } from 'react';

type Theme = 'light' | 'dark';
type ThemeContextType = { theme: Theme; toggleTheme: () => void };

const ThemeContext = React.createContext<ThemeContextType>({} as ThemeContextType);

const ThemeProvider: React.FC = ({ children }) => {
	const [theme, setTheme] = useState<Theme>('dark');

	const toggleTheme = React.useCallback(async () => {
		setTheme(theme === 'light' ? 'dark' : 'light');
	}, [theme]);

	const value = React.useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

function useTheme(): any {
	const context: any = React.useContext(ThemeContext);
	if (context === undefined) {
		throw new Error(`useTheme must be used within a ThemeProvider`);
	}
	return context;
}

export { ThemeProvider, useTheme };
