import React from 'react';
import axios, { AxiosResponse } from 'axios';
import config from '../config';
import { useAuth } from '@montel/montelpro-shared-components/providers';
import FullPageSpinner from '../components/FullPageSpinner';

type UserMappingContextType = {
	isMapped: boolean;
	InsertUserMapping: (username: string, password: string) => Promise<AxiosResponse | undefined>;
	DeleteUserMapping: () => Promise<AxiosResponse | undefined>;
};

const userMappingContext = React.createContext<UserMappingContextType | null>(null);

const UserMappingProvider: React.FC = ({ children }) => {
	const {
		user: { token: user },
	} = useAuth();
	const [isMapped, setMapped] = React.useState(false);
	const [isFetching, setFetching] = React.useState(true);

	React.useEffect(() => {
		const checkUserMapping = async () => {
			await axios
				.get(`${config.apiHost}/api/UserMapping/`)
				.then((response) => {
					setMapped(true);
				})
				.catch(function (error) {
					if (error.response.status === 404) {
						setMapped(false);
					}
					console.error(error);
				})
				.finally(() => setFetching(false));
		};
		checkUserMapping();
	}, [user]);

	const InsertUserMapping = async (username: string, password: string): Promise<AxiosResponse | undefined> => {
		const response = await axios
			.post(`${config.apiHost}/api/UserMapping`, {
				username: username,
				password: password,
			})
			.then((response) => {
				setMapped(true);
				return response;
			})
			.catch((error) => {
				console.error(error);
				return error.response;
			});

		return response;
	};

	const DeleteUserMapping = async (): Promise<AxiosResponse | undefined> => {
		const response = await axios
			.delete(`${config.apiHost}/api/UserMapping`)
			.then((response) => {
				setMapped(false);
				return response;
			})
			.catch((error) => error.response);

		return response;
	};

	return (
		<userMappingContext.Provider value={{ isMapped, InsertUserMapping, DeleteUserMapping }}>
			{isFetching ? <FullPageSpinner></FullPageSpinner> : children}
		</userMappingContext.Provider>
	);
};

function useUserMapping(): UserMappingContextType {
	const context: any = React.useContext(userMappingContext);
	if (context === undefined) {
		throw new Error(`useUserMapping must be used within a UserMappingProvider`);
	}
	return context;
}

export { UserMappingProvider, useUserMapping };
