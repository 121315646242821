const firebaseConfig = {
	apiKey: 'AIzaSyC4gS-3suMHw8C2GFmLoQg4Ktamk98OFx8',
	authDomain: 'montel-notifier.firebaseapp.com',
	projectId: 'montel-notifier',
	storageBucket: 'montel-notifier.appspot.com',
	messagingSenderId: '198145901030',
	appId: '1:198145901030:web:806d92f2fb041b3c441627',
	measurementId: 'G-DBZYB37S7G',
};

const vapidKey = 'BBtes0g-1I7e-rLhcfsEJUVIBSWhUPlVwjTGVnGLoke2EfMRh5szglJFnUB3b8-qSuZpnyU3ImMBM_qjtjCeJCc';

export { firebaseConfig, vapidKey };
