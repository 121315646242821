import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AppProvider from './context/AppProvider';
import { ThemeProvider } from './context/ThemeProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CompleteEmailVerification from './pages/CompleteEmailVerification';

ReactDOM.render(
	<ErrorBoundary>
		<Router>
			<Switch>
				<Route path="/complete-email-verification" component={CompleteEmailVerification} />
				<Route path="*">
					<AppProvider>
						<ThemeProvider>
							<App />
						</ThemeProvider>
					</AppProvider>
				</Route>
			</Switch>
		</Router>
	</ErrorBoundary>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
serviceWorker.registerFirebaseServiceWorker();
