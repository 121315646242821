import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router';
import config from '../config';
import parseQueryString from '../utils/parseQueryString';
import redirectTo from '../utils/redirectTo';

const CompleteEmailVerification: FunctionComponent = () => {
	const location = useLocation();
	const { verificationId, tenantId } = parseQueryString(location.search);
	useEffect(() => {
		redirectTo(`${config.identityProvider}/email/verify/${verificationId}?tenantId=${tenantId}`);
	}, []);
	return null;
};

export default CompleteEmailVerification;
