const REACT_APP_ENV = window._env_?.REACT_APP_ENV ?? process.env.REACT_APP_ENV;

if (REACT_APP_ENV === 'local') {
	module.exports = {
		apiHost: 'https://localhost:5001',
		authServer: 'http://localhost:9000',
		identityProvider: 'https://account-dev.montelnews.com',
		loginRedirect: 'http://localhost:9010',
		fusionauthApplicationId: '33415ae1-511d-4fe8-bdcd-da8a45a3ed5f',
	};
} else if (REACT_APP_ENV === 'dev') {
	module.exports = {
		apiHost: 'https://api-alert-dev.montelnews.com',
		authServer: 'https://authenticate-dev.montelgroup.com',
		identityProvider: 'https://account-dev.montelnews.com',
		loginRedirect: 'https://alert-dev.montelnews.com/',
		fusionauthApplicationId: '33415ae1-511d-4fe8-bdcd-da8a45a3ed5f',
	};
} else if (REACT_APP_ENV === 'test') {
	module.exports = {
		apiHost: 'https://api-alert-test.montelnews.com',
		authServer: 'https://authenticate-dev.montelgroup.com',
		identityProvider: 'https://account-dev.montelnews.com',
		loginRedirect: 'https://alert-test.montelnews.com/',
		fusionauthApplicationId: '33415ae1-511d-4fe8-bdcd-da8a45a3ed5f',
	};
} else if (REACT_APP_ENV === 'prod') {
	module.exports = {
		apiHost: 'https://alerts-api.montelgroup.com',
		authServer: 'https://authenticate.montelgroup.com',
		identityProvider: 'https://account.montelnews.com',
		loginRedirect: 'https://alerts.montelgroup.com/',
		fusionauthApplicationId: '9fdddf0d-73cf-4d2a-b2f7-f205f8e4f052',
	};
}
