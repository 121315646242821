import firebase from 'firebase/app';
import axios from 'axios';
import config from '../config';
import 'firebase/messaging';
import React from 'react';
import FullPageSpinner from '../components/FullPageSpinner';
import { firebaseConfig, vapidKey } from '../firebase-config';
import { useAuth } from '@montel/montelpro-shared-components/providers';

const firebaseContext = React.createContext({});
const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
const messaging: any = firebase.messaging.isSupported() && initializedFirebaseApp.messaging();

const FirebaseProvider: React.FC = ({ children }) => {
	const [isLoading, setLoading] = React.useState(false);
	const {
		user: { token: user },
	} = useAuth();

	React.useEffect(() => {
		async function insertTokenToDatabase(browserToken: string) {
			await axios
				.put(`${config.apiHost}/api/Token/PutBrowserToken/`, {
					token: browserToken,
				})
				.catch(function (error) {
					console.log(error);
				});
		}

		async function handleRequestPermissionAndTokenInsertion() {
			Notification.permission !== 'denied' &&
				Notification.requestPermission()
					.then(async function () {
						const token = await messaging.getToken({
							vapidKey: vapidKey,
						});
						insertTokenToDatabase(token);
					})
					.catch(function (err: any) {
						console.log('Unable to get permission to notify.', err);
					});
		}

		setLoading(true);
		handleRequestPermissionAndTokenInsertion();
		setLoading(false);
	}, [user.sub]);

	const deleteFirebaseToken = React.useCallback(async () => {
		Notification.permission === 'granted' &&
			(await axios
				.delete(`${config.apiHost}/api/Token/RemoveBrowserToken/`, {
					data: {
						token: await messaging.getToken({
							vapidKey: vapidKey,
						}),
					},
				})
				.then(async function (response) {
					await messaging.deleteToken();
				})
				.catch(function (error) {
					console.log(error);
				}));
	}, []);

	const value = React.useMemo(() => ({ messaging, deleteFirebaseToken }), [deleteFirebaseToken]);

	return (
		<firebaseContext.Provider value={value}>
			{isLoading ? <FullPageSpinner></FullPageSpinner> : children}
		</firebaseContext.Provider>
	);
};

function useFirebase(): any {
	const context: any = React.useContext(firebaseContext);
	if (context === undefined) {
		throw new Error(`useFirebase must be used within a FirebaseProvider`);
	}
	return context;
}

export { FirebaseProvider, useFirebase };
