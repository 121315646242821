import React from 'react';
import { ErrorBoundary as ErrorBoundaryHandler } from 'react-error-boundary';

const ErrorBoundary: React.FC = ({ children }) => {
	function ErrorFallback({ error, resetErrorBoundary }: any) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		);
	}

	function myErrorHandler(error: Error, info: { componentStack: string }) {
		// Do something with the error
		// E.g. log to an error logging client here
		console.log(error);
		console.log(info);
	}

	return (
		<ErrorBoundaryHandler FallbackComponent={ErrorFallback} onError={myErrorHandler}>
			{children}
		</ErrorBoundaryHandler>
	);
};

export default ErrorBoundary;
