import React from 'react';
import { Spin } from 'antd';

const FullPageSpinner: React.FC = () => {
	return (
		<div
			style={{
				fontSize: '4em',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Spin tip="Loading..." />
		</div>
	);
};

export default FullPageSpinner;
